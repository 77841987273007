import { Github, Linkedin } from "lucide-react";

function Footer(): JSX.Element{
    return(
        <div className="footer mt-5 p-5">
            <div className="row ">
                <div className="col-lg-6">
                    <p className="text-center text-light">
                        &copy; 2024 Abubak'r Mufutau-Oseni. All rights reserved.
                    </p>
                </div>
                <div className="col-lg-6 text-center">
                    <a
                    href="https://www.linkedin.com/in/abubakrmo/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                        <Linkedin className="linkedin-footer" size={28}/>
                    </a>
                    <a
                    href="https://github.com/abubakrmo"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                        <Github className="github-footer" size={28}/>
                    </a>
                </div>
            </div>
        </div>
    )
}
export default Footer;